import { BrowserRouter, Route, Routes } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from "./components/Navbar";
import Megamenu from "./components/Megamenu";
import NewMenu from "./components/nav/NewMenu";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Products from "./pages/Products";
import Services from "./pages/Services";
import ContactUs from "./pages/ContactUs";
import Footer from "./components/Footer";
import NewFooter from "./components/NewFooter";
import Error from "./pages/Error";
import Login from "./components/user/Login";
import './App.css';
import ProductDetail from "./components/product/ProductDetail";
import Register from "./components/user/Register";
import { loadUser } from './actions/userActions';
import { useEffect, useState } from 'react';
import Profile from './components/user/Profile';
import ProtectedRoute from './components/routes/ProtectedRoute';
import UpdateProfile from './components/user/UpdateProfile';
import UpdatePassword from './components/user/UpdatePassword';
import Dashboard from './components/admin/Dashboard';
import store from './store';
import ProductList from "./components/admin/ProductList";
import NewProduct from "./components/admin/NewProduct";
import Currentprice from "./components/admin/Currentprice";
import Upateprice from "./components/admin/Upateprice";
import Updateproduct from "./components/admin/Updateproduct";
import Order from "./components/admin/Order";
import Newnavbar from "./components/nav/Newnavbar";
import { Cart } from "./components/cart/Cart";
import { Return } from "./pages/Return";
import { Returndetails } from "./components/admin/Returndetails";
import { Shiping } from "./components/cart/Shiping";
import { Orderconfirm } from "./components/cart/Orderconfirm";
import Userorder from "./components/user/Userorder";




function App() {

    useEffect(() => {
    store.dispatch(loadUser)
  })

  return (
    <BrowserRouter>
      <HelmetProvider>
        {/* <Navbar /> */}
        {/* <Megamenu />  */}
       {/* <NewMenu/>  */}
    <Newnavbar />
        <ToastContainer theme="dark"/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/products" element={<Products />} />
          <Route path="/services" element={<Services />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/return" element={<Return/>} />
          <Route path='/login' element={<Login/>}/>
          <Route path='/register' element={<Register/>}/>
          <Route path='/product/:id' element={<ProductDetail/>} />
          <Route path="products/:subcategory" element={<Products />} />
          <Route path='/myprofile' element={<ProtectedRoute><Profile/></ProtectedRoute> } />
          <Route path='/myprofile/update' element={<ProtectedRoute><UpdateProfile/></ProtectedRoute> } />
          <Route path='/myprofile/update/password' element={<ProtectedRoute><UpdatePassword/></ProtectedRoute> } />
          <Route path='/admin/dashboard' element={<ProtectedRoute isAdmin={true}><Dashboard/></ProtectedRoute> } />
          <Route path='/admin/products' element={ <ProtectedRoute isAdmin={true}><ProductList/></ProtectedRoute> } />
          <Route path='/admin/products/create' element={ <NewProduct/> } />
          <Route path='/admin/currentprice' element={ <ProtectedRoute isAdmin={true}><Currentprice /></ProtectedRoute> } />
          <Route path='/admin/update/price/:id' element={ <ProtectedRoute isAdmin={true}><Upateprice /></ProtectedRoute> } />
          <Route path='/admin/update/product/:id' element={ <ProtectedRoute isAdmin={true}><Updateproduct /></ProtectedRoute> } />
          <Route path='/admin/order' element={ <ProtectedRoute isAdmin={true}><Order /></ProtectedRoute> } />
          <Route path='/cart' element={<Cart />} />
          <Route path='/shipping' element={<Shiping />} />
          <Route path='/orderconfrim' element={<Orderconfirm />} />
          <Route path='/admin/return' element={ <ProtectedRoute isAdmin={true}><Returndetails /></ProtectedRoute> } />
          <Route path="/user/order" element={<Userorder />} />
          {/* Define the "Error" component for all other paths */}
          <Route path="*" element={<Error />} />
        </Routes>

           {/* Admin Routes */}
            {/* <Routes>
                <Route path='/admin/dashboard' element={ <ProtectedRoute><Dashboard/></ProtectedRoute> } />
            </Routes> */}
        <NewFooter />
      </HelmetProvider>
     
    </BrowserRouter>
  );
}

export default App;
