import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { saveShippingInfo } from "../../slices/cartSlice";
import { useNavigate } from "react-router-dom";

export const Shiping = () => {
   const {shippingInfo} = useSelector((state) => state.cartState)
   const dispatch = useDispatch()
const navigate = useNavigate()

const [fullname,setFullname] = useState(shippingInfo.fullname)
const [phone_No,setPhone_No]=useState(shippingInfo.phone_No)
const [email,setEmail]= useState(shippingInfo.email)
const [house_No,setHouse_No] = useState(shippingInfo.house_No)
const [street,setStreet] = useState(shippingInfo.street)
const [city,setCity] = useState(shippingInfo.city)
const [district,setDistrict]=useState(shippingInfo.district)
const [postalCode,setPostalCode] = useState(shippingInfo.postalCode)




 const [errors, setErrors] = useState({});

   
    // const validateForm = () => {
    //     const errors = {};
    //     let isValid = true;
    
    //     //name required
    //     if (!shippingData.fullname) {
    //       errors.fullname = "Username is required";
    //       isValid = false;
    //     }
    //  //house required
    //  if (!shippingData.houseNo) {
    //     errors.houseNo = "house no is required";
    //     isValid = false;
    //   }
      
    //   if (!shippingData.street) {
    //     errors.street = "street is required";
    //     isValid = false;
    //   }

    //   if (!shippingData.city) {
    //     errors.city = "city is required";
    //     isValid = false;
    //   }
      
    //   if (!shippingData.district) {
    //     errors.district = "district is required";
    //     isValid = false;
    //   }
    //   if (!shippingData.postalcode) {
    //     errors.postalcode = "postal code is required";
    //     isValid = false;
    //   }
    
      
    //   
    
    //  
    //   };

      const handleSubmit = (e)=>{
        e.preventDefault();
        let formErrors = {};

        //full name validate
        if (!fullname) {
            formErrors.fullname = "Full Name is required";
        }

        //phone number validate
        if(!phone_No){
          formErrors.fullname = "Phone Number is required";
        }

         //Validate email
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email) {
          formErrors.email = "Email is required";
         
        }
        else if(!emailPattern.test(email)){
          formErrors.email = "Invalid email format";
        
        }

         // Validate phone number
        const phonePattern = /^\d{10}$/; // Assuming a 10-digit phone number format
        if (!phone_No) {
          formErrors.phoneno = " phone number is required ";
        
        }
        else if(!phonePattern.test(phone_No)){
          formErrors.phoneno = "Invalid phone number format";
          
        }

          //house required
     if (!house_No) {
        formErrors.houseNo = "house no is required";
     
      }
       // validate street
      if (!street) {
        formErrors.street = "street is required";
       
      }

      //validate city
      if (!city) {
        formErrors.city = "city is required";
       
      }
      
      //validate district
      if (!district) {
        formErrors.district = "district is required";
       
      }

      //validate postal code
      if (!postalCode) {
        formErrors.postalcode = "postal code is required";
       
      }
        if (Object.keys(formErrors).length === 0) {
          // No errors, proceed with saving shipping info
          dispatch(saveShippingInfo({fullname,phone_No,email,house_No,street,city,district,postalCode}));
          navigate('/orderconfrim');
      } else {
          // Errors found, update state to display errors
          setErrors(formErrors);
      }
        
      }

  return (
    <div className="md:mt-10 container xl:max-w-6xl mx-auto px-2 py-4 lg:px-2 lg:py-4   h-full">
      <div className=" flex items-center justify-center mt-20 md:mt-10">
        <form className="w-full max-w-lg" onSubmit={handleSubmit}>
            <div className="bg-white shadow-xl p-10">
                <h2 className="text-xl font-bold text-center mb-5">Shipping Information</h2>
                <div className="flex flex-wrap -mx-3 mb-3">
              <div className="w-full px-3">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
               Full Name
              </label>
              <input
               name="fullname"
               value={fullname}
               onChange={(e)=> setFullname(e.target.value)}
               className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-amber-500 
                ${errors.fullname ? "border-red-500":"" }`}
                id="grid-password"
                type="text"
                placeholder="Enter Your Name"
              />
              {errors.fullname && (
                    <p className="error text-sm text-red-500">
                      {errors.fullname}
                    </p>
                  )}
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-3">
              <div className="w-full px-3">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
               Phone Number
              </label>
              <input
              name="phoneno"
              value={phone_No}
              onChange={(e) => setPhone_No(e.target.value)}
              className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-amber-500 
             `}
                id="grid-password"
                type="text"
                placeholder="Enter Your phone No"
              />
             
               {errors.phoneno && (
                    <p className="error text-sm text-red-500">
                     {errors.phoneno}
                    </p>
                  )} 
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-3">
              <div className="w-full px-3">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Email 
              </label>
              <input
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-amber-500 
              `}
                id="grid-password"
                type="text"
                placeholder="Enter Your Email"
              />
              {errors.email && (
                    <p className="error text-sm text-red-500">
                      {errors.email}
                    </p>
                  )}
            </div>
          </div>
          <div className=" mt-10 flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                House No
              </label>
              <input
              name="houseNo"
              value={house_No}
              onChange={(e) => setHouse_No(e.target.value)}
              className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-amber-500 
               `}
                id="grid-first-name"
                type="text"
                placeholder="NO-01"
              />
              {errors.houseNo && (
                    <p className="error text-sm text-red-500">
                      {errors.houseNo}
                    </p>
                  )}
            </div>
            <div className="w-full md:w-1/2 px-3">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
               Street
              </label>
              <input
              name="street"
              value={street}
              onChange={(e) =>setStreet(e.target.value)}
              className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-amber-500 
             `}
                id="grid-last-name"
                type="text"
                placeholder="Street"
              />
                {errors.street && (
                    <p className="error text-sm text-red-500">
                      {errors.street}
                    </p>
                  )}
            </div>
          </div>
        
          <div className="flex flex-wrap -mx-3 mb-2">
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                City
              </label>
              <input
              name="city"
              value={city}
              onChange={(e) =>setCity(e.target.value)}
              className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-amber-500 
            `}
                id="grid-city"
                type="text"
                placeholder="city"
              />
                {errors.city && (
                    <p className="error text-sm text-red-500">
                      {errors.city}
                    </p>
                  )}
            </div>
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              district
              </label>
              <input
              name="district"
              value={district}
              onChange={(e) => setDistrict(e.target.value)}
              className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-amber-500 
            `}
                id="grid-city"
                type="text"
                placeholder="jaffna"
              />
                {errors.district && (
                    <p className="error text-sm text-red-500">
                      {errors.district}
                    </p>
                  )}
            </div>
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Postal code
              </label>
              <input
              name="postalcode"
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
              className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-amber-500 
             `}
                id="grid-zip"
                type="text"
                placeholder="90210"
              />
                {errors.postalcode && (
                    <p className="error text-sm text-red-500">
                      {errors.postalcode}
                    </p>
                  )}
            </div>
          </div>
          <div className="flex mt-8 justify-center">
           <button className="bg-primary py-2 px-8 rounded-xl text-white hover:bg-orange-600">SUBMIT</button>
           </div>
          </div>
        </form>
      </div>
    </div>
  );
};
