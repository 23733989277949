import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { DeleteOrders, getAllOrders } from "../../actions/orderAction";
import { clearError } from "../../slices/orderSlice";
import Loader from "../Loader";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const Order = () => {
  const {
    adminOrders = [],
    loading = true,
    error,
    isOrderDeleted,
  } = useSelector((state) => state.orderState);
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredOrders, setFilteredOrders] = useState([])

  useEffect(() => {
    if (error) {
      toast(error, {
        position: toast.POSITION.BOTTOM_CENTER,
        type: "error",
        onOpen: () => {
          dispatch(clearError());
        },
      });
      return;
    }

    if (isOrderDeleted) {
    }

    dispatch(getAllOrders);
  }, [dispatch, error, isOrderDeleted]);

  const handleClick = (e, id) => {
    e.target.disable = true;
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(DeleteOrders(id));
      }
    });
  };

  const [open, setOpen] = useState(null);
  console.log(searchQuery);

 useEffect(() =>{
  setFilteredOrders(
    adminOrders.filter((order) =>
      order.OrderId.toLowerCase().includes(searchQuery.toLowerCase())
    )
  )
  
 },[adminOrders,searchQuery])

  return (
    <div className="flex">
      <Sidebar />
      {loading ? (
        <Loader />
      ) : (
        //         <div className="h-screen flex-1 p-7 text-black overflow-auto md:overflow-x-hidden ">
        //           <h1 className="mt-3 text-2xl font-bold text-center">Order Details</h1>
        //
        //         </div>
        <div className="h-screen flex-1 p-7 text-black overflow-auto overflow-x-scroll  ">
          <h1 className="my-4 text-4xl font-bold">All Order Details</h1>
          <div className=" h-screen overflow-x-scroll">
            <section className="items-center lg:flex bg-white font-poppins dark:bg-gray-800 ">
              <div className="justify-center flex-1 max-w-6xl px-4 py-4 mx-auto lg:py-8 md:px-6">
                <div className=" rounded shadow bg-white ">
                  <div className="pt-4 dark:bg-gray-900 flex flex-wrap items-center justify-between px-6 pb-4 border-b dark:border-gray-700">
                    <h2 className="mb-4 text-xl font-bold md:mb-0 dark:text-gray-400">
                      List of All order Details
                    </h2>
                    <div className="flex px-6 py-2 mb-4 border border-gray-600 rounded-md md:mb-0 dark:border-gray-400">
                      <input
                      value={searchQuery}
                      onChange={(e) =>setSearchQuery(e.target.value)}
                        type="text"
                        className="w-full pr-4 text-sm text-gray-700 bg-stone-100 dark:text-gray-400 dark:bg-gray-900 placeholder-text-100"
                        placeholder="search..."
                      />
                      <button className="flex items-center text-gray-700 dark:text-gray-400 dark:hover:text-blue-300 hover:text-blue-600">
                        <span className="mr-2 text-xs">Go</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-arrow-right"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="p-4 px-8 w-auto">
                    {/* <div className=" flex flex-row  items-end font-bold  w-full ">
                      <p className="w-1/5 ml-6">OrderId</p>
                      <p className="w-1/5 ml-12">Name</p>
                      <p className="w-1/5 ml-10">Phone_No</p>

                      <p className="w-1/5 ml-12">Order Date</p>
                      <p className="">Action</p>
                    </div> */}
                    {filteredOrders
                      .slice()
                      .reverse()
                      .map((order) => (
                        <div key={order.OrderId} className="mb-3">
                          <button
                            onClick={() =>
                              setOpen(
                                open === order.OrderId ? null : order.OrderId
                              )
                            }
                            className=" flex flex-row  w-full justify-between items-center py-3 border-b-2 border-b-slate-300"
                          >
                            <p className="w-1/5">{order.OrderId}</p>
                            <p className="w-1/5">{order.shippingInfo.fullname}</p>
                            <p className="w-1/5">{order.shippingInfo.phone_No}</p>
                            <p className="w-1/5">{ String(order.createdAt).substring(0, 10)}</p>
                            <button onClick={(e) => handleClick(e,order._id)}>
                              <MdDelete className="text-red-500 text-xl" />
                            </button>
                          </button>
                          {open === order.OrderId && (
                            <div className="grid overflow-hidden transition-all duration-300 ease-in-out bg-gray-900 text-white ">
                              <div className="overflow-hidden flex flex-row px-5 py-3 ">
                                <div className=" w-1/2 px-3">
                                  <h2 className="p-3 text-lg font-bold">
                                    Customer Details
                                  </h2>
                                  <p>Order ID:{order.OrderId}</p>
                                  <p>Name:{order.shippingInfo.fullname}</p>
                                  <p>Phone_No:{order.shippingInfo.phone_No}</p>
                                  <p>Email:{order.shippingInfo.email}</p>
                                  <p>
                                    Address:
                                    {order.shippingInfo.house_No},{" "}
                                    {order.shippingInfo.street}
                                  </p>
                                  <p>
                                    {order.shippingInfo.city} ,{" "}
                                    {order.shippingInfo.district}
                                  </p>
                                </div>
                                <div className="w-1/2">
                                  {order.orderItems.map((item) => (
                                    <div className="flex justify-around space-x-2 mb-5 border-b-2 border-b-primary py-3">
                                      <img
                                        src={item.image}
                                        alt="product"
                                        className="h-24 w-24"
                                      />
                                      <div className="flex flex-col space-y-2 items-start justify-start text-xs">
                                        <p>ID:{item.product}</p>
                                        <p>Item:{item.name}</p>
                                        <p>Weight:{item.weight}g</p>
                                        <p>
                                          Price:
                                          {(item.price / 8) * item.weight +
                                            (item.price * item.makingrate) /
                                              100}
                                        </p>
                                        <p>Quantity:{item.quantity}</p>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      )}
    </div>
  );
};

export default Order;
