import React, { useState } from "react";
import HeaderLogo from "../images/HeaderLogo.webp";
import { IoIosArrowForward } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaDribbble } from "react-icons/fa6";
import logo from "../images/igoldtras.png";

const NewFooter = () => {
  function getDate() {
    const today = new Date();
    const year = today.getFullYear();

    return `${year}`;
  }

  const [currentDate, setCurrentDate] = useState(getDate());
  return (
    <footer className="bg-custom-footer dark:bg-custom-footer  mt-0">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-5 w-full p-5">
        <div className=" ml-5 h-max flex flex-col justify-center items-center p-4 md:justify-start md:items-start space-y-5">
          <img
            src={logo}
            alt="logo"
            className="md:cursor-pointer h-[5.5rem] "
          />

          <p className="text-gray-200">
            Transforming jewelry with exquisite craftsmanship. We reimagine
            pieces, offering quality and affordability. Rediscover elegance as
            old becomes new. Welcome to the IGOLD experience.
          </p>
          <div>
            <ul className="mt-4 flex justify-center gap-6 sm:mt-0 sm:justify-start">
              <li className="bg-amber-500  hover:bg-amber-600 rounded-full p-2">
                <a
                  href="/"
                  rel="noreferrer"
                  target="_blank"
                  className="text-white"
                >
                  <FaFacebookF className="h-5 w-5" />
                </a>
              </li>

              <li className="bg-amber-500  hover:bg-amber-600 rounded-full p-2">
                <a
                  href="/"
                  rel="noreferrer"
                  target="_blank"
                  className="text-white"
                >
                  <FaInstagram className="h-5 w-5" />
                </a>
              </li>

              <li className="bg-amber-500  hover:bg-amber-600 rounded-full p-2">
                <a
                  href="/"
                  rel="noreferrer"
                  target="_blank"
                  className="text-white"
                >
                  <FaTwitter className="h-5 w-5" />
                </a>
              </li>

              <li className="bg-amber-500  hover:bg-amber-600 rounded-full p-2">
                <a
                  href="/"
                  rel="noreferrer"
                  target="_blank"
                  className="text-white"
                >
                  <FaLinkedinIn className="h-5 w-5" />
                </a>
              </li>

              {/* <li className="bg-amber-500  hover:bg-amber-600 rounded-full p-2">
                <a
                  href="/"
                  rel="noreferrer"
                  target="_blank"
                  className="text-white"
                >
                  <FaDribbble className="h-5 w-5" />
                </a>
              </li> */}
            </ul>
          </div>
        </div>

        <div className="  h-max flex flex-row justify-start space-y-5 ">
          <div className=" w-1/2 mt-5">
            <p className="text-white font-bold text-xl">Other Pages</p>
            <ul className="mt-3 ">
              <li>
                <Link
                  className="flex flex-row space-x-2 text-white hover:text-slate-300  hover:font-semibold  mt-3"
                  to={"/"}
                >
                  <IoIosArrowForward className="mt-1 text-amber-500 font-bold" />
                  <p>Home</p>
                </Link>
              </li>
              <li>
                <Link
                  className="flex flex-row space-x-2 hover:font-semibold text-white hover:text-slate-300  mt-3"
                  to={"/"}
                >
                  <IoIosArrowForward className="mt-1 text-amber-500 font-bold" />
                  <p>About Us</p>
                </Link>
              </li>
              <li>
                <Link
                  className="flex flex-row space-x-2 hover:font-semibold text-white hover:text-slate-300  mt-3 "
                  to={"/"}
                >
                  <IoIosArrowForward className="mt-1 text-amber-500 font-bold" />
                  <p>Jewellery</p>
                </Link>
              </li>
              <li>
                <Link
                  className="flex flex-row space-x-2 hover:font-semibold text-white hover:text-slate-300  mt-3"
                  to={"/"}
                >
                  <IoIosArrowForward className="mt-1 text-amber-500 font-bold" />
                  <p>Contact Us</p>
                </Link>
              </li>
              <li>
                <Link
                  className="flex flex-row space-x-2 hover:font-semibold text-white hover:text-slate-300  mt-3"
                  to={"/"}
                >
                  <IoIosArrowForward className="mt-1 text-amber-500 font-bold" />
                  <p>Return</p>
                </Link>
              </li>
            </ul>
          </div>
          <div className=" w-1/2 mt-5">
            <p className="text-white font-bold text-xl">Quick Links</p>
            <ul className="mt-3 ">
              <li>
                <Link
                  className="flex flex-row space-x-2 hover:font-semibold text-white hover:text-slate-300 mt-3"
                  to={"/"}
                >
                  <IoIosArrowForward className="mt-1 text-amber-500 font-bold" />
                  <p>Privacy Policy</p>
                </Link>
              </li>
              <li>
                <Link
                  className="flex flex-row space-x-2 hover:font-semibold text-white hover:text-slate-300  mt-3"
                  to={"/"}
                >
                  <IoIosArrowForward className="mt-1 text-amber-500 font-bold" />
                  <p>Term of Service</p>
                </Link>
              </li>
              <li>
                <Link
                  className="flex flex-row space-x-2 hover:font-semibold text-white hover:text-slate-300  mt-3 "
                  to={"/"}
                >
                  <IoIosArrowForward className="mt-1 text-amber-500 font-bold" />
                  <p>Credits</p>
                </Link>
              </li>
              <li>
                <Link
                  className="flex flex-row space-x-2 hover:font-semibold text-white hover:text-slate-300  mt-3"
                  to={"/"}
                >
                  <IoIosArrowForward className="mt-1 text-amber-500 font-bold" />
                  <p>FAQ</p>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className=" h-max flex flex-col justify-center items-center p-4 md:justify-start md:items-start space-y-5">
          <p className="text-white font-bold text-xl">Newsletter</p>
          <p></p>

          <input
            type="text"
            id="hero-input"
            name="hero-input"
            className="py-3 px-4 block w-full  md:w-3/4 border-transparent shadow-sm rounded-md focus:z-10  focus:border-amber-500 border-2"
            placeholder="Enter your email"
          />
          <button className="uppercase  w-full md:w-3/4 py-3 bg-amber-500 rounded-md hover:bg-amber-600 text-white font-semibold">
            Subscribe
          </button>
        </div>
      </div>
      <div className="">
        <div className="border-b-[1px] border-slate-500 mt-5"></div>
        <p className="text-center text-sm text-white   py-5">
          Copyright &copy; {currentDate} | Gold(Pvt)Ltd.All rights reserved. |
          <span className="hover:text-[#F59E0C]">
            <a href="https://www.matrixmantra.lk/">
              {" "}
              Web Design & Development by Matrix Mantra
            </a>
          </span>
        </p>
      </div>
    </footer>
  );
};

export default NewFooter;
