import HeaderLogo from "../../images/HeaderLogo.webp";
import logo from "../../images/igoldtras.png";
import { Link, useNavigate } from "react-router-dom";
import NavLinks from "./NavLinks";
import Button from "./Button";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/userActions";
import { FaUser } from "react-icons/fa";
import { CiLogin } from "react-icons/ci";
import { PiShoppingCartSimpleFill } from "react-icons/pi";

const Newnavbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const threshold = 100; // Adjust this threshold as needed
      setIsScrolled(scrollTop > threshold);
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //current date
  function getDate() {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    return `${date}/${month}/${year}`;
  }

  const [currentDate, setCurrentDate] = useState(getDate());

  const [currentgoldprice, setCurrentgoldprice] = useState([]);
  //get current price from databse
  useEffect(() => {
    const fetchCurrentPrice = async () => {
      try {
        const response = await axios.get(
          "https://igold.teammatrixmantra.com/goldprice/admin/show"
        );
        setCurrentgoldprice(response.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchCurrentPrice();
  }, []);

  const [open, setOpen] = useState(false);
  const { isAuthenticated, user } = useSelector((state) => state.authState);
  const { items: cartItems } = useSelector((state) => state.cartState);
  const [userOpen, setUserOpen] = useState(false);

  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  // Define toggleMenu function to toggle the open state
  const toggleMenu = () => {
    setOpen(!open);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoutHandler = () => {
    dispatch(logout);
  };

  // Reset dropdown state when the component mounts or the authentication status changes
  useEffect(() => {
    setDropdownOpen(false);
  }, [isAuthenticated]);

  return (
    <nav className=" sticky top-0 z-50 shadow-xl ">
      {/* gold price start */}
      <div
        className={`md:block hidden text-amber-500 font-extrabold ${
          isScrolled ? "bg-black" : "bg-white"
        }`}
      >
        {currentgoldprice.map((price) => (
          <div>
            <marquee>
              Date:{currentDate} Today Gold Rate Rs.{price.goldprice}
            </marquee>
          </div>
        ))}
      </div>
      {/* gold price end */}
      {/* start menu */}
      <div className="flex  font-medium justify-between bg-black text-white items-center relative">
        {/* for mobile */}
        <div className="z-50  md:w-auto w-full flex flex-col relative fixed top-0 bg-black md:static md:top-auto justify-center  ">
          <div className="md:hidden py-1  bg-amber-500 w-full">
            {currentgoldprice.map((price) => (
              <div>
                <marquee>
                  Date:{currentDate} Today Gold Rate Rs.{price.goldprice}
                </marquee>
              </div>
            ))}
          </div>
          <div className="flex justify-between  px-3  bg-black text-white  items-center ">
            <div
              className="text-2xl md:hidden ml-3 z-50"
              onClick={() => setOpen(!open)}
            >
              <i class={`fas ${open ? "fa-times" : "fa-bars"}`}></i>
            </div>
            <div className="flex text-center justify-center self-center ">
              {" "}
              <img
                src={logo}
                alt="logo"
                className=" h-[5rem] md:hidden -ml-[100px] max-w-96"
              />
              <div className=" items-center hidden xs:flex -ml-[20px] font-bodoni text-[#FED100]">
                Jewelleries{" "}
              </div>
            </div>

            {/* cart and user */}
            <div className="flex flex-row items-center justify-center text-2xl space-x-2">
              {/* user  */}
              {/* <div
                className="text-2xl md:hidden mr-3"
                onClick={() => setUserOpen(!userOpen)}
              >
                {userOpen ? (
                  
                      <CiLogin />
                   
                ) : (
                 
                    
                      <FaUser />
               
           
                )}
              </div> */}

              <div className="md:hidden">
                {isAuthenticated ? (
                  <div className="max-w-6xl  mx-auto flex items-center">
                    <div className="relative inline-block text-left">
                      <div>
                        <button onClick={toggleDropdown} type="button">
                          <figure className="avatar avatar-nav">
                            {/* <img
                              width="50px"
                              src={user.avatar ?? "./images/default_avatar.png"}
                              className="w-10 h-10 object-cover rounded-full "
                              alt="User Avatar"
                            /> */}
                          </figure>
                        </button>
                      </div>
                      {isDropdownOpen && (
                        <div className="absolute right-0 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg dark:bg-gray-700 ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {/* Dropdown content goes here */}
                          {user.role === "admin" && (
                            <div className="py-1">
                              <Link
                                to="/admin/dashboard"
                                // onClick={() => navigate('/myprofile')}
                                className="flex px-4 py-2 text-sm text-gray-700 dark:text-gray-400 dark:hover:text-gray-300 hover:text-blue-500"
                              >
                                <span className="mr-2">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="w-4 h-4 bi bi-person-circle"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                    <path
                                      fillRule="evenodd"
                                      d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                                    />
                                  </svg>
                                </span>
                                Dashboard
                              </Link>
                            </div>
                          )}
                          <div className="py-1">
                            <Link
                              to="/myprofile"
                              // onClick={() => navigate('/myprofile')}
                              className="flex px-4 py-2 text-sm text-gray-700 dark:text-gray-400 dark:hover:text-gray-300 hover:text-blue-500"
                            >
                              <span className="mr-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="w-4 h-4 bi bi-person-circle"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                  <path
                                    fillRule="evenodd"
                                    d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                                  />
                                </svg>
                              </span>
                              Profile
                            </Link>
                          </div>
                          {/* delete */}
                          <div className="py-1">
                            <Link
                              to="/user/order"
                              // onClick={() => navigate('/myprofile')}
                              className="flex px-4 py-2 text-sm text-gray-700 dark:text-gray-400 dark:hover:text-gray-300 hover:text-blue-500"
                            >
                              <span className="mr-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="w-4 h-4 bi bi-person-circle"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                  <path
                                    fillRule="evenodd"
                                    d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                                  />
                                </svg>
                              </span>
                              Orders
                            </Link>
                          </div>
                          {/* Add more dropdown items here */}
                          <div className="py-1">
                            <Link
                              to="/"
                              onClick={logoutHandler}
                              className="flex px-4 py-2 text-sm text-gray-700 dark:text-gray-400 dark:hover:text-gray-300 hover:text-blue-500"
                            >
                              <span className="mr-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="w-4 h-4 bi bi-person-circle"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                  <path
                                    fillRule="evenodd"
                                    d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                                  />
                                </svg>
                              </span>
                              Logout
                            </Link>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <Link to="/login">
                    <button
                      className="text-2xl text-white "
                      onClick={toggleMenu}
                    >
                      <FaUser />
                    </button>
                  </Link>
                )}
              </div>

              {/* cart */}
              <div className="md:hidden">
                <Link to={`/cart`}>
                  <div class=" flex justify-center items-center">
                    <div class="relative ">
                      <div class="t-0 absolute left-3">
                        <p class="flex h-2 w-2 items-center justify-center rounded-full bg-primary p-2 text-xs text-white">
                          {cartItems.length || 0}
                        </p>
                      </div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="file: mt-2 h-6 w-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                        />
                      </svg>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="hidden justify-around uppercase items-start  space-x-12 font-[Poppins] z-10  md:flex ">
          <div>
            {" "}
            <ul className="md:flex hidden uppercase items-center space-x-12 mt-5  ">
              <li>
                <Link
                  to="/"
                  className="py-5 px-3 inline-block hover:text-[#FFD201]"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/aboutus"
                  className="py-5 px-3 inline-block  hover:text-[#FFD201] "
                >
                  About Us
                </Link>
              </li>
            </ul>
          </div>
          <div
            className={` p-10 rounded-full absolute top-2 z-10 left-[45%]  transition-all duration-500 right-[50%] ${
              isScrolled ? "mt-5" : "-mt-[9px]"
            }`}
          >
            <img
              src={logo}
              alt="logo"
              className={`md:cursor-pointer h-[7.5rem] absolute max-w-44 top-0 -mt-[18px] -ml-[85px] w-[300px]
                
              }`}
            />
          </div>

          <div
            className={`absolute p-16 h-24  lg:w-[120px] -mt-5 rounded-full bg-black xl:left-[43.5%] xxl:left-[43.8%] z-0 right-[50%] transition-all duration-500 ${
              isScrolled ? "xxl:w-[160px]" : "xxl:w-[120px]"
            } ${isScrolled ? "xxl:left-[42.9%]" : "xxl:left-[43.8%]"} ${
              isScrolled ? "mt-0" : "-mt-6"
            }`}
          >
            {" "}
            {""}
          </div>

          <div className="-ml-28">
            <ul className="md:flex hidden uppercase items-center space-x-12 mt-5 ">
              <NavLinks />

              <li className="">
                <Link
                  to="/contactus"
                  className="py-5 px-3 inline-block  hover:text-[#FFD201]"
                >
                  Contacts
                </Link>
              </li>

              <li>
                <Link
                  to="/return"
                  className="py-5 px-3 inline-block   hover:text-[#FFD201]"
                >
                  Return
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* end menu*/}

        {/* user role and login */}
        <div className=" md:flex items-center hidden mr-10 space-x-3">
          <div>
            {isAuthenticated ? (
              <div className="max-w-6xl px-4 mx-auto flex items-center">
                <figure className="avatar avatar-nav">
                  {/* <img
                    width="50px"
                    src={user.avatar ?? "./images/default_avatar.png"}
                    className="w-10 h-10 object-cover rounded-full mr-2"
                    alt="User Avatar"
                  /> */}
                </figure>

                <div className="relative inline-block text-left">
                  <div>
                    <button
                      onClick={toggleDropdown}
                      type="button"
                      className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-blue-700 border border-blue-300 rounded-md shadow-sm dark:border-gray-600 dark:text-gray-400 dark:bg-gray-700 bg-gray-50 hover:bg-blue-50 focus:outline-none focus:ring-2 dark:focus:ring-0 focus:ring-offset-2 focus:ring-offset-gray-100 dark:focus:ring-offset-gray-700 focus:ring-blue-500 "
                    >
                      {/* <span>{user.name}</span> */}
                      <svg
                        className={`w-4 h-4 ml-2 -mr-1 fill-current ${
                          isDropdownOpen ? "rotate-180" : "rotate-0"
                        }`}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        style={{ marginTop: "3px" }}
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </button>
                  </div>
                  {isDropdownOpen && (
                    <div className="absolute right-0 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg dark:bg-gray-700 ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {/* Dropdown content goes here */}
                      {user.role === "admin" && (
                        <div className="py-1">
                          <Link
                            to="/admin/dashboard"
                            // onClick={() => navigate('/myprofile')}
                            className="flex px-4 py-2 text-sm text-gray-700 dark:text-gray-400 dark:hover:text-gray-300 hover:text-blue-500"
                          >
                            <span className="mr-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="w-4 h-4 bi bi-person-circle"
                                viewBox="0 0 16 16"
                              >
                                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                <path
                                  fillRule="evenodd"
                                  d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                                />
                              </svg>
                            </span>
                            Dashboard
                          </Link>
                        </div>
                      )}
                      <div className="py-1">
                        <Link
                          to="/myprofile"
                          // onClick={() => navigate('/myprofile')}
                          className="flex px-4 py-2 text-sm text-gray-700 dark:text-gray-400 dark:hover:text-gray-300 hover:text-blue-500"
                        >
                          <span className="mr-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="w-4 h-4 bi bi-person-circle"
                              viewBox="0 0 16 16"
                            >
                              <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                              <path
                                fillRule="evenodd"
                                d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                              />
                            </svg>
                          </span>
                          Profile
                        </Link>
                      </div>
                      {/* delete */}
                      <div className="py-1">
                        <Link
                          to="/user/order"
                          // onClick={() => navigate('/myprofile')}
                          className="flex px-4 py-2 text-sm text-gray-700 dark:text-gray-400 dark:hover:text-gray-300 hover:text-blue-500"
                        >
                          <span className="mr-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="w-4 h-4 bi bi-person-circle"
                              viewBox="0 0 16 16"
                            >
                              <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                              <path
                                fillRule="evenodd"
                                d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                              />
                            </svg>
                          </span>
                          Orders
                        </Link>
                      </div>
                      {/* Add more dropdown items here */}
                      <div className="py-1">
                        <Link
                          to="/"
                          onClick={logoutHandler}
                          className="flex px-4 py-2 text-sm text-gray-700 dark:text-gray-400 dark:hover:text-gray-300 hover:text-blue-500"
                        >
                          <span className="mr-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="w-4 h-4 bi bi-person-circle"
                              viewBox="0 0 16 16"
                            >
                              <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                              <path
                                fillRule="evenodd"
                                d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                              />
                            </svg>
                          </span>
                          Logout
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <Link to="/login">
                <button
                  className="bg-amber-500 text-white px-6 py-2 rounded-md"
                  onClick={toggleMenu}
                >
                  Login
                </button>
              </Link>
            )}
          </div>
          <Link to="/cart">
            <div class=" flex justify-center items-center">
              <div class="relative py-2">
                <div class="t-0 absolute left-3">
                  <p class="flex h-2 w-2 items-center justify-center rounded-full bg-primary p-3 text-xs text-white">
                    {cartItems.length || 0}
                  </p>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="file: mt-4 h-6 w-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                  />
                </svg>
              </div>
            </div>
          </Link>
        </div>
      </div>

      {/* for mobile view*/}
      <div>
        <div>
          {" "}
          <ul
            className={`
        md:hidden bg-white z-10 fixed w-80 h-max  top-8 overflow-y-auto bottom-0 pt-24 pb-10 pl-4 justify-center ml-5
        duration-500 text-black ${open ? "left-0" : "left-[-100%]"} 
        `}
          >
            <li>
              <Link
                to="/"
                className="py-3 px-3 inline-block border-b-[1px] w-full border-b-gray-300"
                onClick={toggleMenu}
              >
                Home
              </Link>
            </li>

            <li>
              <Link
                to="/aboutus"
                className="py-3 px-3 inline-block border-b-[1px] w-full border-b-gray-300"
                onClick={toggleMenu}
              >
                About Us
              </Link>
            </li>
            <NavLinks onClick={toggleMenu} />
            <li>
              <Link
                to="/contactus"
                className="py-3 px-3 inline-block border-b-[1px] w-full border-b-gray-300"
                onClick={toggleMenu}
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div>
        <ul
          className={`
        md:hidden bg-red-300 z-10 fixed w-80 h-max  top-20  overflow-y-auto bottom-0 pt-24 pb-10 pl-4 justify-center ml-5
        duration-500 text-black ${userOpen ? "left-0" : "left-[-100%]"} 
        `}
        >
          <li>
            <div className="py-1">
              <Link
                to="/myprofile"
                // onClick={() => navigate('/myprofile')}
                className="flex px-4 py-2 text-sm text-gray-700 dark:text-gray-400 dark:hover:text-gray-300 hover:text-blue-500"
              >
                <span className="mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="w-4 h-4 bi bi-person-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                    <path
                      fillRule="evenodd"
                      d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                    />
                  </svg>
                </span>
                Profile
              </Link>
            </div>{" "}
            <div className="py-1">
              <Link
                to="/myprofile"
                // onClick={() => navigate('/myprofile')}
                className="flex px-4 py-2 text-sm text-gray-700 dark:text-gray-400 dark:hover:text-gray-300 hover:text-blue-500"
              >
                <span className="mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="w-4 h-4 bi bi-person-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                    <path
                      fillRule="evenodd"
                      d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                    />
                  </svg>
                </span>
                Profile
              </Link>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Newnavbar;
