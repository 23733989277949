import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearAuthError, login } from '../../actions/userActions';
import MetaData from '../MetaData';
import { toast } from 'react-toastify';
import { Link, useLocation, useNavigate } from 'react-router-dom';


export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { loading, error, isAuthenticated } = useSelector(state => state.authState);
  const redirect = location.search ? '/' +  location.search.split('=')[1] : '/';

  const submitHandler = (e) => {
    e.preventDefault();

    if(isAuthenticated) {
      navigate(redirect)
  }
    dispatch(login(email, password));
  };

  useEffect(() => {
    if (isAuthenticated) {
      // navigate(redirect);
      navigate(redirect)    
    }

    if (error) {
      toast(error, {
        position: toast.POSITION.BOTTOM_CENTER,
        type: 'error',
        onOpen: () => {
          dispatch(clearAuthError);
        },
      });
      return;
    }
  }, [error, isAuthenticated, dispatch, navigate]);

  return (
    <Fragment>
      <MetaData heading={`Login`} />
      <div className="flex wrapper">
        <div className="max-w-md w-10/12">
          <form onSubmit={submitHandler} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          {/* <form className="rounded px-8 py-6 pb-8 mb-4"> */}
            <h1 className="mb-6 text-5xl font-bold">Login</h1>

            <div className="mb-4">
              <label htmlFor="email_field" className="block text-gray-700 text-xl font-bold mb-2">
                Email
              </label>
              <input
                type="email"
                id="email_field"
                className="block w-full px-4 py-2 mt-1 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="mb-4">
              <label htmlFor="password_field" className="block text-gray-700 text-xl font-bold mb-2">
                Password
              </label>
              <input
                type="password"
                id="password_field"
                className="block w-full px-4 py-2 mt-1 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <Link to="/password/forgot" className="float-right mb-4 hover:underline">
              Forgot Password?
            </Link>

            <button
              id="login_button"
              type="submit"
              className="w-full py-3 bg-customOrange hover:bg-customOrange text-white font-bold rounded focus:outline-none focus:shadow-outline"
              disabled={loading}
            >
              {loading ? 'Logging in...' : 'Login'}
            </button>

            <Link to="/register" className="float-right mt-4 hover:underline">
              New User?
            </Link>
          </form>
        </div>
      </div>
    </Fragment>
  );
}
