import axios from "axios"
import { addCartItemRequest, addCartItemSuccess } from "../slices/cartSlice"

export  const addCartItem =(id,price,quantity,userid) => async(dispatch)=>{
    try {
        dispatch(addCartItemRequest())
        const {data} = await axios.get(`/jewellery/product/${id}`)
        dispatch(addCartItemSuccess({
            product: data.product._id,
            name:data.product.name,
            weight:data.product.weight,
            image:data.product.images[0].image,
            stock:data.product.stock,
            makingrate:data.product.makingrate,
            quantity,
            price,
            userid

        }))
    } catch (error) {
        
    }
}

