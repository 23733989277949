import { createSlice } from "@reduxjs/toolkit";


const orderSlice = createSlice({
  name: "order",
  initialState: {
    orderDetail:{},
    userOrders: [],
    adminOrders:[],
    loading:false,
    isOrderDeleted: false,

  },
  reducers: {
   createOrderRequest(state,action){
    return{
        ...state,
        loading:true
    }
   },

createOrderSuccess(state,action){
    return{
        ...state,
        loading:false,
    
        orderDetail:action.payload
    }
},
createOrderFail(state,action){
    return{
        ...state,
        loading:true,
        error:action.payload
    }
},
AllOrderRequest(state,action){
  return{
    ...state,
    loading:true
  }
},AllorderSuccess(state,action){
  return{
    ...state,
    loading:false,
    adminOrders:action.payload
  }
},
AllorderFail(state,action){
  return{
    ...state,
    loading:false,
    error:action.payload
  }
},
deleteOrder(state,action){
  return{
    ...state,
    loading:true
  }
},
deleteOrderSuccess(state,action){
  return{
    ...state,
    adminOrders :state.adminOrders.filter(order => order._id !== action.payload),
    loading:false,
    isOrderDeleted: true

  }

},
deleteOrderFail(state,action){
  return{
    ...state,
    loading:false,
    error:action.payload
  }
},
clearOrderDeleted(state, action) {
  return {
      ...state,
      isOrderDeleted: false
  }
},

clearError(state, action){
  return {
      ...state,
      error:  null
  }
},
userOrderRequest(state,action){
  return{
    ...state,
    loading:true
  }
},
userOrderSuccess(state,action){
  return{
    ...state,
    loading:false,
    userOrders:action.payload.data
  }

  },

  userOrerFail(state,action){
    return{
      ...state,
      loading:false,
      error:action.payload
    }
  }
  }
});

const { actions, reducer } = orderSlice;
export const {
createOrderRequest,createOrderSuccess,createOrderFail,
AllOrderRequest,AllorderSuccess,AllorderFail,clearError,
deleteOrderFail,deleteOrder,deleteOrderSuccess,clearOrderDeleted,
userOrderRequest,userOrderSuccess,userOrerFail
} = actions;
export default reducer;
