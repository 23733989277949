import React from 'react'
import { getAdminProducts } from "../../actions/productActions";
import { Link } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import { useState } from "react";
import { useEffect } from "react";


const Sidebar = ({ dashboardHeight }) => {
  // const defaultHeightClass = 'h-screen';
  console.log('dashboardHeight:', dashboardHeight);

  const customHeightClass = dashboardHeight ? `height: ${dashboardHeight}` : 'height: 100vh';
  console.log('customHeightClass:', customHeightClass);
 
  
 

    const [open, setOpen] = useState(true);
    const Menus = [
      { title: "Dashboard", src: "Chart_fill", link:"/admin/dashboard" },
      { title: "All Users", src: "User", link:"/admin/users" },
      { title: "Update Users", src: "User", link:"/admin/user/:id" },
      { title: "All Products", src: "Chart",  link:"/admin/products", gap: true },
      { title: "Add Product", src: "Chart", link:"/admin/products/create" },
    
      { title: "Current Price", src: "Chart", link:"/admin/currentprice" },
      { title: "Order Details", src: "Calendar", link:"/admin/order", gap: true  },
      { title: "Return Details", src: "Search", link:"/admin/return"  },
      { title: "Analytics", src: "Chart", link:"/admin/dashboard"  },
      { title: "Files ", src: "Folder", gap: true, link:"/admin/dashboard"  },
      { title: "Setting", src: "Setting", link:"/admin/dashboard"  },
    ];

    const [selectedMenu, setSelectedMenu] = useState(0); 
   

    const handleMenuClick = (index) => {  
      setSelectedMenu(index === null ? 0 : index);
    };

  
    
    
  
    const { products = [] } = useSelector( state => state.productsState);
    const dispatch = useDispatch();
  
    useEffect( () => {
      console.log('Updated selectedMenu:', selectedMenu);

      dispatch(getAdminProducts);
     
   }, [selectedMenu])

  
  return (
    <div>
    <div
        className={` ${
          open ? "w-72" : "w-20 "
        } bg-dark-purple p-5 pt-[80px] md:pt-8 relative duration-300 hidden md:block`}
        style={{ height: dashboardHeight }}
      >


        <img
          src="/images/assets/control.png"
          className={`absolute cursor-pointer -right-3 top-9 w-7 border-dark-purple
           border-2 rounded-full  ${!open && "rotate-180"}`}
          onClick={() => setOpen(!open)}
        />
        <div className="flex  gap-x-4 items-center">
          <img
            src="/images/assets/logo.png"
            className={`cursor-pointer duration-500 ${
              open && "rotate-[360deg]"
            }`}
          />
          <Link to="/admin/dashboard">
            <h1
            className={`text-white origin-left font-medium text-xl duration-200 ${
                !open && "scale-0"
            }`}
            >
            User Dashboard
            </h1>
        </Link>
        </div>
        <ul className="pt-6">
          {Menus.map((Menu, index) => (
            <li
              key={index}
              className={`flex  rounded-md p-2 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4 
              ${Menu.gap ? "mt-9" : "mt-2"} menu-item
              ${index === selectedMenu || (selectedMenu === null && index === selectedMenu) ? "bg-light-white" : ""}
              } `}
              onClick={() => {
                handleMenuClick(index);
              }}
            >
             <Link to={Menu.link} className="flex items-center gap-x-2">
                <img src={`/images/assets/${Menu.src}.png`} className="" />
                <span className={`${!open && "hidden"} origin-left duration-200`}>
                    {Menu.title}
                </span>
             </Link>
              
            </li>
          ))}
        </ul>
      </div>
      <div
        className={` ${
          open ? "w-72" : "w-20 "
        } bg-dark-purple  p-5 pt-[80px] md:pt-8 relative duration-300 block md:hidden`}
        style={{ height: dashboardHeight }}
        onClick={() => setOpen(!open)}
      >
        <img
          src="/images/assets/control.png"
          className={`absolute cursor-pointer -right-3 top-9 w-7 border-dark-purple
           border-2 rounded-full  ${!open && "rotate-180"}`}
          onClick={() => setOpen(!open)}
        />
        <div className="flex  gap-x-4 items-center">
          <img
            src="/images/assets/logo.png"
            className={`cursor-pointer duration-500 ${
              open && "rotate-[360deg]"
            }`}
          />
          <Link to="/admin/dashboard">
            <h1
            className={`text-white origin-left font-medium text-xl duration-200 ${
                !open && "scale-0"
            }`}
            >
            User Dashboard
            </h1>
        </Link>
        </div>
        <ul className="pt-6">
          {Menus.map((Menu, index) => (
            <li
              key={index}
              className={`flex  rounded-md p-2 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4 
              ${Menu.gap ? "mt-9" : "mt-2"} ${
                index === 0 && "bg-light-white"
              } `}
            >
             <Link to={Menu.link} className="flex items-center gap-x-2">
                <img src={`/images/assets/${Menu.src}.png`} className="" />
                <span className={`${!open && "hidden"} origin-left duration-200`}>
                    {Menu.title}
                </span>
             </Link>
              
            </li>
          ))}
        </ul>
      </div>
      </div>
  )
}

export default Sidebar