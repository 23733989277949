import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';


const RelatedProductDetailGrid = ({product,   currentgoldprice}) => {
  
  return (
    

    <div>
     
    <div>
      <Link to={`/product/${product._id}`} onClick={() => window.scrollTo(0, 0)}>
      <div className="max-w-md mx-auto rounded-md overflow-hidden shadow-md   hover:shadow-lg">
        <div className="relative">
          {/* <img className="w-full" src={product.images[0].image} alt={product.name} /> */}
          <div className="h-64 overflow-hidden">
              <img className="w-full h-full object-cover" src={product.images[0].image} alt={product.name} />
            </div>
          
        </div>
        <div className="p-4">
          <h3 className="text-lg font-medium mb-2">
          {/* <Link to={`/product/${product._id}`}>{product.name}</Link> */}
            {/* {product.name} */}
            </h3>
          <p className="text-gray-600 text-sm mb-4">
          {product.description}
          </p>
          <div className="flex items-center justify-between">
            <span className="font-bold text-lg">Rs {(currentgoldprice/8 * product.weight) + (currentgoldprice*product.makingrate/	100)}</span>
            <button className="bg-[#feb406] hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
              Buy Now
            </button>
          </div>
        </div>
      </div>
      </Link>
     
    </div>
    </div>
  );
};

export default RelatedProductDetailGrid;
